<template>
  <div class="fadeIn">
    <van-nav-bar
      title="班次设置"
      left-text="返回"
      right-text="添加班组"
      left-arrow
      @click-left="$router.go(-1)"
      @click-right="(groupshow = true), (formShiftgroup.shiftGroupName = '')"
    />
    <div class="GroupBody">
      <div class="box" v-for="item in shiftGroupList" :key="item.shiftGroupId">
        <van-row
          style="
            padding: 10px;
            background-color: #f7f8fa;
            border-bottom: 1px solid #dee1e6;
            height: 30%;
          "
        >
          <van-col span="16">
            <h4 style="display: inline-block; margin-right: 5px">
              {{ item.shiftGroupName }}
            </h4>
            <van-icon
              name="edit"
              color="#1989fa"
              size="0.4rem"
              @click="updateGroup(item)"
          /></van-col>
          <van-col span="8"
            ><span style="font-size: 12px; color: #a2a7a5"
              >分组ID:{{ item.shiftGroupId }}</span
            >
            <span
              style="font-size: 12px; color: #1989fa; float: right"
              @click="deleteShiftGroup(item.shiftGroupId)"
              >删除分组</span
            ></van-col
          >
        </van-row>
        <van-row style="height: 70%">
          <van-col
            :span="10"
            style="border-right: 1px solid #e7e7e8; height: 100%"
            class="elCol"
          >
            <span style="color: #989795; font-size: 12px; margin-right: 10%"
              >添加班次</span
            ><span
              style="color: #1989fa; font-size: 12px"
              @click="
                (show = true), (formShift.shiftGroupId = item.shiftGroupId)
              "
              >设置</span
            >
            <p style="margin-top: 8%">
              <span style="font-size: 12px">已关联班次：</span>
              <span
                style="color: #1989fa; font-size: 12px"
                @click="
                  getShift(item.shiftGroupId),
                    (formShift.shiftGroupId = item.shiftGroupId)
                "
                >查看</span
              >
            </p>
          </van-col>
          <van-col :span="14" class="elCol">
            <span style="color: #989795; font-size: 12px; margin-right: 10%"
              >关联项目</span
            ><span
              style="color: #1989fa; font-size: 12px"
              @click="editProjectList(item)"
              >配置</span
            >
            <p style="margin-top: 8%">
              <span style="font-size: 12px">已关联设备：</span>
              <span style="color: #1989fa; font-size: 12px">
                {{ item.projectCount || 0 }}台</span
              >
            </p>
          </van-col>
        </van-row>
      </div>
    </div>
    <el-dialog title="班次列表" :visible.sync="shiftListShow" width="100%">
      <el-table :data="shiftData" height="300">
        <el-table-column label="序号" type="index" width="40"></el-table-column>
        <el-table-column
          label="班次名称"
          prop="shiftName"
          width="50"
        ></el-table-column>
        <el-table-column
          label="开始时间"
          prop="startTime"
          :formatter="formatter"
        ></el-table-column>
        <el-table-column
          width="110"
          label="结束时间"
          prop="endTime"
          :formatter="formatter1"
        ></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-button
                type="warning"
                round
                size="mini"
                @click="getdetails(scope.row)"
                plain
                >编辑</el-button
              >
            </div>
            <div style="margin-top: 5px">
              <el-button
                type="danger"
                round
                size="mini"
                @click="deleteShift(scope.row)"
                plain
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <div style="margin: 20px auto">
      <!-- <el-table :data="shiftData">
        <el-table-column label="序号" type="index" width="40"></el-table-column>
        <el-table-column
          label="班次名称"
          prop="shiftName"
          width="50"
        ></el-table-column>
        <el-table-column
          label="开始时间"
          prop="startTime"
          :formatter="formatter"
        ></el-table-column>
        <el-table-column
          width="110"
          label="结束时间"
          prop="endTime"
          :formatter="formatter1"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div>
              <el-button
                type="warning"
                round
                size="mini"
                @click="getdetails(scope.row)"
                plain
                >编辑</el-button
              >
            </div>
            <div style="margin-top: 5px">
              <el-button
                type="danger"
                round
                size="mini"
                @click="deleteShift(scope.row)"
                plain
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table> -->
      <van-popup
        v-model="groupshow"
        round
        position="top"
        :style="{ height: '20%', background: '#f7f8fa' }"
      >
        <van-cell-group style="width: 80%; margin-left: 30px">
          <van-field
            required
            style="margin-top: 30px; width: 80%; padding-left: 20px"
            v-model="formShiftgroup.shiftGroupName"
            label="班组名称"
            placeholder="请输入班组名称"
            maxlength="15"
          />
        </van-cell-group>
        <div style="margin: 16px 0 0 70%; width: 20%">
          <van-button round block type="info" @click="addGroupShift" size="mini"
            >提交</van-button
          >
        </div>
      </van-popup>
      <van-popup
        v-model="show"
        round
        position="top"
        :style="{ height: '30%', background: '#f7f8fa' }"
        closeable
        @close="close"
        z-index="999999"
      >
        <h4 style="margin: 20px">
          {{ formShift.shiftId ? "修改班次" : "新增班次" }}
        </h4>
        <van-divider
          :style="{
            borderColor: '#b1b1b1',
            padding: '0 16px',
            margin: '0 2px',
          }"
        />
        <van-form @submit="onSubmit" style="width: 70%; margin: 0 auto">
          <van-field
            v-model="formShift.shiftName"
            label="班次名称"
            placeholder="请输入班次名称"
          />
          <van-field
            readonly
            clickable
            name="datetimePicker"
            :value="formShift.startTime"
            label="开始时间"
            placeholder="点击选择时间"
            @click="(showPicker = true), (date = 0)"
          ></van-field>
          <van-field
            readonly
            clickable
            name="datetimePicker"
            :value="formShift.endTime"
            label="结束时间"
            placeholder="点击选择时间"
            @click="(showPicker = true), (date = 1)"
          >
          </van-field>
          <div style="position: absolute; right: 0; top: 54%">
            <!-- <van-checkbox v-model="startchecked" shape="square"
              >次日</van-checkbox
            > -->
            <van-checkbox v-model="endchecked" shape="square"
              >次日</van-checkbox
            >
          </div>
          <div style="margin: 16px 0 0 70%; width: 30%">
            <van-button round block type="info" native-type="submit" size="mini"
              >提交</van-button
            >
          </div>
          <van-popup v-model="showPicker" position="bottom">
            <van-datetime-picker
              :filter="filter"
              type="time"
              @confirm="onConfirm($event, date)"
              @cancel="showPicker = false"
              :max-hour="24"
            />
          </van-popup>
        </van-form>
      </van-popup>
      <van-popup v-model="projectShow" style="height: 80%; overflow-y: scroll">
        <el-transfer
          :titles="['全部设备', '已关联设备']"
          filterable
          v-model="shiftSharingvalue"
          :data="shiftSharingdata"
          :props="{ key: 'projectId', label: 'projectName' }"
        ></el-transfer>
        <el-row type="flex" justify="end" style="margin-top: 20px">
          <el-alert
            style="width: 200px"
            title="提示：一台设备只能绑定一个班次组！"
            type="info"
            :closable="false"
            show-icon
          >
          </el-alert>
          <el-button
            type="primary"
            round
            size="mini"
            @click="addshiftSharingvalue"
            >添 加</el-button
          >
          <el-button type="danger" round size="mini" @click="clearshiftproject"
            >取 消</el-button
          >
        </el-row>
      </van-popup>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectShow: false,
      formShiftgroup: {
        shiftGroupName: "",
        projectGroupId: this.$store.state.projectGroupId * 1,
      },
      shiftListShow: false,
      shiftGroupList: [],
      shiftData: [],
      startchecked: false,
      endchecked: false,
      date: 0,
      value: "",
      showPicker: false,
      groupshow: false,
      show: false,
      formShift: {
        shiftName: "",
        startTime: "",
        endTime: "",
        nextDay: "",
        shiftGroupId: "",
      },
      shiftSharingdata: [],
      shiftSharingvalue: [],
      itemData: {},
    };
  },
  created() {},
  methods: {
    addshiftSharingvalue() {
      let from = {
        shiftGroupId: this.itemData.shiftGroupId,
        projectIds: this.shiftSharingvalue,
      };
      this.$api.shiftSet.shiftAllot(from).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.clearshiftproject();
        }
      });
    },
    clearshiftproject() {
      this.shiftSharingvalue = [];
      this.projectShow = false;
    },
    async editProjectList(item) {
      this.projectShow = true;
      this.itemData = item;
      this.shiftSharingvalue = [];
      //所有未绑定设备
      await this.$api.shiftSet
        .nallotProjectList({
          projectGroupId: this.$store.state.projectGroupId * 1,
          projectName: "",
        })
        .then((res) => {
          if (res.code == 200) {
            this.shiftSharingdata = res.data;
          }
        });
      //当前组已绑定设备
      await this.$api.shiftSet
        .allotProjectList(item.shiftGroupId)
        .then((res) => {
          if (res.code == 200) {
            res.data.forEach((it) => {
              this.shiftSharingvalue.push(it.projectId);
              this.shiftSharingdata.push({
                projectId: it.projectId,
                projectName: it.projectName,
              });
            });
          }
        });
    },
    updateGroup(item) {
      this.formShiftgroup = { ...item };
      this.groupshow = true;
    },
    //添加班组
    async addGroupShift() {
      try {
        let res = "";
        if (this.formShiftgroup.shiftGroupId) {
          let from = {
            shiftGroupId: this.formShiftgroup.shiftGroupId,
            projectGroupId: this.$store.state.projectGroupId * 1,
            shiftGroupName: this.formShiftgroup.shiftGroupName,
          };
          res = await this.$api.shiftSet.upShiftGroup(from);
        } else {
          res = await this.$api.shiftSet.addShiftGroup(this.formShiftgroup);
        }
        if (res.code == 200) {
          (this.groupshow = false), this.getGroupList();
          this.$message.success(res.msg);
        }
      } catch (e) {
        this.$message.info(e.msg || "操作失败");
      }
    },
    //删除班次组
    deleteShiftGroup(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.shiftSet.delShiftGroup(id).then((res) => {
            if (res.code == 200) {
              this.getGroupList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getGroupList() {
      this.$api.shiftSet
        .allShiftList(this.$store.state.projectGroupId * 1)
        .then((res) => {
          if (res.code == 200) {
            this.shiftGroupList = res.data;
          }
        });
    },
    //获取班次
    getShift(id) {
      this.$api.shiftSet.getShift(id).then((res) => {
        if (res.code == 200) {
          this.shiftListShow = true;
          this.shiftData = res.data;
        }
      });
    },
    //班次提交
    async onSubmit() {
      try {
        let start = this.startchecked ? "1" : "0";
        let end = this.endchecked ? "1" : "0";
        this.formShift.nextDay = start + end;
        let res = {};
        if (this.formShift.shiftId) {
          res = await this.$api.shiftSet.upDateShift(this.formShift);
        } else {
          res = await this.$api.shiftSet.addShift(this.formShift);
        }
        if (res.code == 200) {
          this.$toast.success(res.msg);
          this.close();
          // this.getShift();
        }
      } catch (e) {
        this.$notify(e.msg);
      }
    },
    getdetails(row) {
      this.show = true;
      this.formShift = row;
      console.log(row);
      let status = row.nextDay.split("");
      if (status[0] == 1) {
        this.startchecked = true;
      } else {
        this.startchecked = false;
      }
      if (status[1] == 1) {
        this.endchecked = true;
      } else {
        this.endchecked = false;
      }
    },
    deleteShift(row) {
      this.$dialog
        .confirm({
          title: "删除操作",
          message: "此操作会永久删除文件，继续吗？",
        })
        .then(() => {
          this.$api.shiftSet.deleteShift(row.shiftId).then((res) => {
            if (res.code == 200) {
              this.$toast.success("删除成功!");
              this.getShift(this.formShift.shiftGroupId);
            }
          });
        })
        .catch(() => {
          this.$toast.fail("取消删除");
        });
    },
    close() {
      this.formShift = {
        shiftName: "",
        startTime: "",
        endTime: "",
        nextDay: "",
      };
      this.startchecked = false;
      this.endchecked = false;
      this.show = false;
    },
    filter(type, options) {
      if (type === "minute") {
        return options.filter((option) => option % 30 === 0);
      }
      return options;
    },
    onConfirm(time, type) {
      if (type) {
        this.formShift.endTime = time;
      } else {
        this.formShift.startTime = time;
      }
      this.showPicker = false;
    },
    formatter(val) {
      let status = val.nextDay.split("");
      if (status[0] == 1) {
        return "次日" + " " + val.startTime;
      } else {
        return val.startTime;
      }
    },
    formatter1(val) {
      let status = val.nextDay.split("");
      if (status[1] == 1) {
        return "次日" + " " + val.endTime;
      } else {
        return val.endTime;
      }
    },
  },
  mounted() {
    this.getGroupList();
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.GroupBody {
  width: 100%;
  height: calc(100% - 60px);
  padding: 0 5px;
  overflow-y: auto;
}
.GroupBody .box {
  text-align: left;
  margin-top: 10px;
  width: 100%;
  height: 20%;
  border-radius: 10px;
  border: 1px solid #dee1e6;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.elCol {
  padding: 10px 20px;
  font-size: 14px !important;
}
::v-deep .el-checkbox-group {
  text-align: left;
}
::v-deep .el-transfer__buttons {
  margin-top: 10px !important;
}
</style>
